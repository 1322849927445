<script>
  import { get } from 'vuex-pathify'

  export default {
    props: {
      type: {
        type: String,
        required: true,
        validator: (value) => ['magazine', 'magazinePreview', 'eco-upgrade'].includes(value),
      },
    },
    data() {
      return {
        images: [
          '/static/magazine/magazine-01-spring-22.jpg',
          '/static/magazine/magazine-02-spring-22.jpg',
          '/static/magazine/magazine-03-spring-22.jpg',
        ],
      }
    },
    computed: {
      ...get('config', ['currentSeason']),
    },
    methods: {
      scrollToBottom() {
        this.$refs.learnMore.scrollIntoView({ behavior: 'smooth' })
      },
    },
  }
</script>

<template>
  <div>
    <div class="px-4 md:px-10">
      <div v-if="type === 'magazinePreview'" class="md:mt-2 text-center text-lg">
        {{ currentSeason }} Issue
      </div>
      <h1
        :class="`${
          type === 'magazinePreview' ? 'py-3' : 'md:mt-8'
        } md:px-4 font-heading text-4xl font-medium text-center leading-none`"
      >
        <span v-if="type === 'magazine'">
          Receive our brand new<br />
          premium magazine,<br />
          All Good Things
        </span>
        <span v-else-if="type === 'magazinePreview'"> All Good Things </span>
        <span v-else>Add Eco-Upgrade</span>
      </h1>
      <h2 v-if="type !== 'eco-upgrade'" class="md:px-10 pt-2 pb-4 text-center leading-tight">
        {{
          type === 'magazinePreview'
            ? `This issue is filled with flowers (yay spring!), including a guide to what’s blooming where, a how-to on crafting with pressed petals, and a feature on why blossoms bring us joy.`
            : 'Upgrade to receive our seasonal guide for intentional living with your box'
        }}
      </h2>
    </div>

    <div class="md:px-6">
      <!-- Product image slider + gallery -->
      <BaseSliderWithGallery
        v-if="type !== 'eco-upgrade'"
        :images="images"
        alt="magazine"
        has-navigation
      />
      <div :class="`px-6 md:px-0 ${type === 'magazinePreview' ? 'mb-4' : 'mb-30'} md:mb-0`">
        <template v-if="type !== 'eco-upgrade'">
          <BaseDivider class="my-4" />
          <p v-if="type === 'magazine'">
            For $7.95/season, you will receive the premium edition of All Good Things magazine! It
            is filled with eco-conscious DIY projects, healthy recipes, exclusive interviews, and
            actionable ways to make a difference. Printed on luxe paper, with high-end detailing —
            it is made to be read from cover-to-cover and look stunning on your coffee table
            afterwards! Retails for $16, get yours for more than 50% off.
          </p>
          <p v-else>
            You’ll also find plenty of fresh perspectives sprouting from these pages. Beloved
            artists and thinkers reveal their definitions of living with intention (it’s “permission
            to pause,” says Jenn Tardiff, founder of 3rd Ritual); Lisa Przystup, author of Upstate:
            Living Spaces with Space to Live, shares her personal tale of building community in
            rural New York; and scientist Joshua Gold gives us the scoop on getting better
            sleep—something we could all use, no matter the season.
            <br /><br />
            It’s all here in your premium edition of All Good Things, which ships with your
            {{ currentSeason }} Box!
          </p>
        </template>
        <template v-else>
          <p class="mt-6">
            Give your Alltrue box a green boost! Make the shipment of your Alltrue box carbon
            neutral by adding a $1/box eco-upgrade and planting 1 tree with every box purchased.
          </p>
          <br />
          <p>
            Your $1/box eco-upgrade directly supports Grow Ahead, a 501(c)(3) non-profit
            organization that connects brands like Alltrue to small-scale farmers and community-led
            reforestation projects that draw carbon out of the atmosphere in a sustainable and
            impactful way. $1 = 1 tree planted.
          </p>
          <br />
          <p>
            Annual Members will be charged $1/box left on their Membership and then $4 when their
            Membership renews.
          </p>
          <br />
          <p>
            100% of proceeds from eco-upgrades will be contributed to Grow Ahead as part of
            Alltrue’s partnership. Your upgrade is not, however, a direct personal donation to Grow
            Ahead nor is it tax deductible.
          </p>
        </template>
        <template v-if="type !== 'magazinePreview'">
          <BaseDivider class="my-4" />
          <p ref="learnMore" class="text-xs mb-8">
            &#42; Your
            {{ type === 'magazine' ? 'upgrade to All Good Things Premium ' : 'eco-upgrade' }} will
            renew automatically along with your Membership using the payment method associated with
            your account. To prevent renewal, you can cancel in your account page, or by emailing us
            at <BaseLinkStyled href="mailto:help@alltrue.com">help@alltrue.com</BaseLinkStyled>. All
            prices listed are in USD.
          </p>
        </template>
      </div>
    </div>
  </div>
</template>
