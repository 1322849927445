<script>
  import { get, call } from 'vuex-pathify'
  import screen from '@/helpers/screen'

  export default {
    props: {
      /** Whether the profile component is being used in the account page  */
      accountView: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        showAvatarModal: false,
        avatarCount: 45, // has to match the number of pre-made avatars
      }
    },
    computed: {
      ...get('account', [
        'getAvatarUrl',
        'avatarUrl',
        'formattedCreatedDate',
        'fullName',
        'isActiveSubscriber',
        'subscriptions',
      ]),
      ...get('config', ['currentBox']),
      screen,
      hasNoSubscriptions() {
        return !this.subscriptions?.length
      },
      byline() {
        if (this.hasNoSubscriptions) {
          return 'Become a Member and build your box!'
        }
        if (!this.isActiveSubscriber) {
          return 'Resume your Membership'
        }
        return `Member since ${this.formattedCreatedDate}`
      },
      cta() {
        if (this.hasNoSubscriptions) {
          return 'Build Your First Box'
        }
        if (!this.isActiveSubscriber && this.currentBox) {
          return `Get the ${this.currentBox.season} ${this.currentBox.year} Box`
        }
        return 'View Account'
      },
      ctaLink() {
        if (this.hasNoSubscriptions || !this.isActiveSubscriber) {
          return { name: 'SubscribePlan' }
        }
        return '/account'
      },
      profileAvatarSize() {
        // has to be square, i.e., height = width in tailwind units
        return this.accountView && this.screen.lg ? 'w-34 h-34' : 'w-30 h-30'
      },
      avatarIndex() {
        return Number(this.avatarUrl?.slice(-6, -4))
      },
    },
    methods: {
      ...call('account', ['updateAvatar']),
      getAvatarFile(i) {
        return `Avatar_${('0' + i).slice(-2)}.png`
      },
      async selectAvatar(i) {
        await this.updateAvatar(this.getAvatarFile(i))
        this.showAvatarModal = false
      },
    },
  }
</script>

<template>
  <div class="bg-dawn">
    <img
      :src="`/static/CoverPhoto${screen.md && accountView ? 'Desktop' : 'Mobile'}.jpg`"
      alt="illustration banner"
      :class="`absolute ${screen.sm && accountView ? 'h-35 w-full' : 'min-h-21'} object-cover`"
    />
    <div
      :class="`flex-col justify-center pt-9 md:pt-6 ${
        accountView ? 'md:pt-17 pb-7 md:pb-10' : ''
      } text-center`"
    >
      <div :class="`mx-auto border-2 border-dawn-dk1 bg-white rounded-full  ${profileAvatarSize}`">
        <BaseImage :src="avatarUrl" alt="Avatar" rounded background-transparent responsive="xs" />
        <button
          v-if="accountView"
          type="button"
          class="absolute bottom-0 right-0 flex justify-center items-center w-9 h-9 rounded-full bg-primary hover:bg-primary-bright active:bg-primary-dark text-white"
          @click="showAvatarModal = true"
        >
          <BaseIcon :size="5">
            <IconPencil />
          </BaseIcon>
        </button>
      </div>
      <div class="font-heading text-xxl mt-4">
        {{ fullName }}
      </div>
      <div class="text-xs mb-1">
        {{ byline }}
      </div>
      <div v-if="!accountView" class="font-semibold mb-7">
        <BaseLink :to="ctaLink">{{ cta }}</BaseLink>
        <BaseIcon :size="4" class="inline-block align-middle">
          <IconChevronRight />
        </BaseIcon>
      </div>
    </div>

    <!-- Avatar selection modal -->
    <BaseModal
      dismissible
      hide-icon
      no-vertical-padding
      size="xs"
      height="md"
      :open="showAvatarModal"
      @dismiss="showAvatarModal = false"
    >
      <div class="z-40 sticky top-0 pt-3 -mx-6 bg-white">
        <BaseIconClickable
          label="Close"
          class="absolute right-0 mr-3 z-90"
          @click="showAvatarModal = false"
        >
          <IconX />
        </BaseIconClickable>
        <div class="text-lg text-center">Avatar</div>
        <BaseImage
          src="/static/CoverPhotoMobile.jpg"
          alt="illustration banner"
          responsive="md"
          class="mt-3"
        />
        <div class="mx-auto -mt-16 w-30 h-30 border-2 border-dawn-dk1 bg-white rounded-full">
          <BaseImage
            rounded
            :src="avatarUrl"
            :alt="`Avatar #${avatarIndex}`"
            background-transparent
            responsive="xs"
            sizes="128px"
          />
        </div>
        <BaseDivider class="mt-4" />
      </div>
      <div class="pt-4 pb-6 text-center">Choose your Avatar</div>
      <div class="flex justify-between flex-wrap mb-6">
        <div
          v-for="i in avatarCount"
          :key="i"
          :class="`avatar ${avatarIndex === i ? 'avatar--chosen' : ''}`"
        >
          <BaseImage
            rounded
            :src="getAvatarUrl(getAvatarFile(i))"
            :alt="`Avatar #${i}`"
            background-transparent
            responsive="xs"
            sizes="128px"
            @click.native="selectAvatar(i)"
          />
          <div
            v-if="avatarIndex === i"
            class="absolute bottom-0 right-0 flex justify-center items-center w-9 h-9 rounded-full bg-primary hover:bg-primary-bright active:bg-primary-dark text-white"
          >
            <BaseIcon :size="4">
              <IconCheckmarkRounded />
            </BaseIcon>
          </div>
        </div>
      </div>
    </BaseModal>
  </div>
</template>

<style lang="postcss" scoped>
  .profilePhoto {
    height: 46%;
    max-height: 130px;

    &--account {
      @screen md {
        min-height: 50%;
      }
    }

    &--home {
      @screen md {
        min-height: 44%;
      }
    }
  }

  .avatar {
    @apply w-25 h-25 m-1 cursor-pointer rounded-full bg-white;

    &--chosen {
      @apply border-4 border-green;
    }

    &:not(.avatar--chosen) {
      @apply border-2 border-dawn-dk1;
    }
  }
</style>
