<script>
  import { get } from 'vuex-pathify'

  export default {
    computed: {
      ...get('achievements', ['getAchievementsForCategory']),
    },
  }
</script>

<template>
  <section>
    <BaseHeading class="text-center" size="h4">Alltrue Achievements</BaseHeading>
    <article class="flex flex-wrap justify-around">
      <figure
        v-for="achievement in getAchievementsForCategory('upgrades')"
        :key="achievement.label"
        class="w-20 text-center my-5 flex-shrink-0"
      >
        <BaseLink :to="{ name: 'AccountAchievement', params: { achievement: achievement.slug } }">
          <component :is="achievement.icon" class="w-17 m-auto" />
          <figcaption class="w-20 text-xs">{{ achievement.label }}</figcaption>
        </BaseLink>
      </figure>
    </article>

    <div class="text-center pt-4 pb-5">
      <BaseLinkStyled :to="{ name: 'AccountAchievements' }" color="black">View More</BaseLinkStyled>
    </div>
  </section>
</template>
