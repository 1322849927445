<script>
  import screen from '@/helpers/screen'

  export default {
    props: {
      /** Whether the modal is currently open (visible). */
      open: {
        type: Boolean,
        default: false,
      },
      /** Whether the modal is dismissible by the user. If `true`, a “X” button appears in the top right corner, and the user can either click on it, click anywhere outside the modal, or press Esc to dismiss it (at which point a `dismiss` event is emitted). */
      dismissible: {
        type: Boolean,
        default: false,
      },
      bgColor: {
        type: String,
        default: 'dawn',
        validator: (value) => ['dawn'].includes(value),
      },
    },
    data() {
      return {
        modalMobileImage: '/static/modals/Holiday-Modal-Mobile.jpg',
        modalDesktopImage: '/static/modals/Holiday-Modal-Desktop.jpg',
      }
    },
    computed: {
      screen,
      modalImageSrc() {
        return this.screen.sm ? this.modalDesktopImage : this.modalMobileImage
      },
      modalAspectRatio() {
        return this.screen.sm ? 0.83 : 1.27
      },
      modalSize() {
        return this.screen.sm ? 'md' : 'sm-md'
      },
      modalHeight() {
        return this.screen.sm ? 'none' : 'md'
      },
      trendingQuestionsButtonText() {
        return this.dismissible ? 'Learn More' : 'Next'
      },
      closeIconBgColor() {
        return screen.sm ? '' : 'close-icon-bg-dawn'
      },
    },
    methods: {
      openNextModal() {
        this.$emit('openNextModal')
      },
      // Don't open another modal if there is a next step
      dismissAll() {
        this.$emit('dismissAll')
      },
    },
  }
</script>

<template>
  <Portal to="modal">
    <BaseModal
      :size="modalSize"
      :height="modalHeight"
      :open="open"
      :bg-color="bgColor"
      :dismissible="dismissible"
      no-horizontal-padding
      no-vertical-padding
      hide-nav-on-mobile
      :class="closeIconBgColor"
      @dismiss="dismissAll"
    >
      <div class="holiday-modal">
        <BaseImage :src="modalImageSrc" :aspect-ratio="modalAspectRatio" responsive="md" alt="" />
        <div
          class="px-25px sm:px-11 sm:flex sm:flex-col sm:justify-center"
          :class="dismissible ? 'py-5 sm:pt-11 sm:pb-9' : 'py-18px sm:py-5'"
        >
          <div v-if="!dismissible" class="text-center text-sm font-bold uppercase mb-1 sm:mb-4">
            New!
          </div>
          <BaseHeading size="h2" tag="div" class="mb-4 text-4xl text-center whitespace-no-wrap">
            <BaseIcon :size="10" class="inline-block align-middle mt-3px mr-1">
              <IconRushShipping />
            </BaseIcon>
            Holiday Delivery
          </BaseHeading>
          <p class="mb-4 text-left" style="line-height: 1.2">
            All products with the <span class="italic">Holiday Delivery</span> badge are eligible
            for expedited delivery, to arrive no later than December 25.
          </p>
          <BaseButton
            class="sm:mb-4 text-md"
            color="primary-dawn"
            :class="dismissible ? 'mb-4' : 'mb-2'"
            @click="openNextModal"
          >
            {{ trendingQuestionsButtonText }}
          </BaseButton>
          <p class="text-center text-2xs italic">*$15 for expedited delivery to Canada</p>
        </div>
      </div>
    </BaseModal>
  </Portal>
</template>

<style lang="postcss" scoped>
  /* purgecss start ignore */
  @screen sm {
    .holiday-modal {
      display: grid;
      grid-template-columns: 39% 61%;
    }
  }

  .close-icon-bg-dawn >>> {
    section > button {
      @apply bg-dawn rounded-full;

      width: 40px !important;
      height: 40px !important;
    }
  }
</style>
