<script>
  import { get, call } from 'vuex-pathify'
  import screen from '@/helpers/screen'
  import HomeWidget from '@/components/home/HomeWidget'

  import IconInstagram from '@/images/icons/IconInstagram.svg'

  export default {
    components: {
      HomeWidget,
      IconInstagram,
    },
    computed: {
      ...get('prismic', ['homeBannerData']),
      customizationOpen() {
        return this.homeBannerData.body[1].primary || ''
      },
      ...get('config', ['currentSeason', 'currentBox', 'screenIsNotBetweenLgAndXl']),
      ...get('customize', [
        'hasOpenBoxCarts',
        'customizationIsLive',
        'hasCustomizableSubscriptions',
        'latestBoxCustomizationDates',
        'hasOnlyComingSoonSubscriptions',
        'customizationStartDate',
      ]),
      ...get('account', [
        'isQuarterlySubscriber',
        'isSubscriptionWaitlist',
        'isSubscriptionOffWaitlist',
      ]),
      screen,
      getBoxCustomizeLink() {
        if (this.hasCustomizableSubscriptions) {
          return { name: 'CustomizeStart' }
        } else if (!this.hasCustomizableSubscriptions) {
          if (this.isQuarterlySubscriber) {
            return { name: 'AccountProfileSubscriptions' }
          }
        }
        return null
      },
      bannerProps() {
        return {
          size: 'large',
          textPosition: this.screen.xl ? 'left' : 'center',
          contentWidth: this.screen.xl ? 'half' : 'full',
          ctaButtonColor: 'black',
          display: this.screen.md && !this.screen.lg ? 'grid' : 'flex',
        }
      },
      currentSeasonColor() {
        return this.currentSeason ? `bg-${this.currentSeason.toLowerCase()}` : 'bg-winter' // fallback in case this.currentSeason is undefined
      },
      boxTitleToDisplay() {
        if (this.currentBox?.label) {
          return this.currentBox?.label
        } else {
          return `${this.currentSeason} Box`
        }
      },
      quarterlyImage() {
        if (this.customizationIsLive) {
          return '/static/home/summer-24-customization.gif'
        } else {
          return this.screen.xl
            ? '/static/customize/Winter_Box_Desktop.jpg'
            : '/static/customize/Spring_Box_Tablet.png'
        }
      },
    },
    async created() {
      await this.loadHomeBannerData()
    },
    methods: {
      ...call('prismic', ['loadHomeBannerData']),
      htmlSerializer(type, element, text, children) {
        if (text) {
          text = text.replace(
            '{{ formattedMarketplaceEndDateLong }}',
            this.formattedMarketplaceEndDateLong
          )
          return text
        }
      },
    },
  }
</script>

<template>
  <HomeWidget v-bind="$attrs" :to="getBoxCustomizeLink">
    <!-- Waitlist Banner -->
    <BaseBanner
      v-if="hasOpenBoxCarts && isSubscriptionWaitlist()"
      v-bind="bannerProps"
      class="bg-winter"
    >
      <template v-slot:header>Stay tuned</template>
      <span class="text-3xl">You’re on the waitlist!</span>
      <template v-slot:description>
        Yay! You’ll receive the next available box — and as a waitlist reserve member, you’ve
        secured $5 off!
      </template>
      <template v-if="screenIsNotBetweenLgAndXl" v-slot:image>
        <BaseImage
          :class="`w-full ${screen.md ? 'py-30px pr-30px' : ''}`"
          :src="
            screen.xl
              ? '/static/home/WinterBoxComingSoonDesktop.jpg'
              : '/static/home/WinterBoxComingSoonMobile.jpg'
          "
          responsive="md"
          background-transparent
          alt=""
        />
      </template>
    </BaseBanner>

    <!-- Annual customize CTA. Customization started -->
    <BaseBanner
      v-else-if="hasCustomizableSubscriptions"
      v-bind="bannerProps"
      :class="'bg-' + customizationOpen.background_color.toLowerCase()"
      data-cy="customization-open"
    >
      <template v-slot:header>
        <div class="pt-2 md:pt-0">
          <span v-if="isSubscriptionOffWaitlist">Waitlist reserve</span>
          <span v-else>{{ boxTitleToDisplay }}</span>
        </div>
      </template>
      <div class="-mx-2 xs:mx-0">
        <PrismicRichText v-if="customizationOpen" :field="customizationOpen.header" />
      </div>
      <template v-slot:description>
        <div class="max-w-90 mx-auto xl:max-w-none">
          {{ isSubscriptionOffWaitlist ? 'You have access.' : '' }}
          <PrismicRichText v-if="customizationOpen" :field="customizationOpen.description_copy" />
        </div>
      </template>
      <template v-slot:cta>Start customizing</template>
      <template v-if="screenIsNotBetweenLgAndXl" v-slot:image>
        <BaseImage
          :class="`w-full ${screen.md ? 'py-30px pr-30px' : ''}`"
          :src="customizationOpen.image.url"
          :alt="customizationOpen.image.alt || 'Banner Image'"
          responsive="md"
          :sizes="{ default: '100vw', md: '50vw', xl: '384px' }"
          background-transparent
        />
      </template>
    </BaseBanner>

    <!-- Important dates (letting users know when customization is live) CTA -->
    <BaseBanner
      v-else-if="!isQuarterlySubscriber && hasOnlyComingSoonSubscriptions"
      v-bind="bannerProps"
      class="bg-spring cursor-default"
      :banner-props="bannerProps"
      :screen-is-not-between-lg-and-xl="screenIsNotBetweenLgAndXl"
      data-cy="customization-open"
    >
      <template v-slot:header>
        <div class="pt-34px md:pt-0">{{ boxTitleToDisplay }}</div>
      </template>
      <div style="white-space: nowrap"> Here comes the sun! </div>
      <template v-slot:description>
        <div class="max-w-64 mx-auto xl:mx-0 pb-18px md:pb-0">
          We hope this season is filled with adventurous days, and magical summer nights— here’s to
          another warm, sunny season together!
          <!-- span class="font-semibold"> Customization opens {{ customizationStartDate }}. </span -->
        </div>
      </template>
      <template v-if="screenIsNotBetweenLgAndXl" v-slot:image>
        <BaseImage
          :class="`${screen.lg ? 'pr-34px py-8' : !screen.md ? 'mt-2 w-full' : ''}`"
          src="/static/customize/Summer_Box_Desktop.jpg"
          alt=""
          responsive="md"
          :sizes="{ default: '100vw', md: '50vw', xl: '384px' }"
          background-transparent
        />
      </template>
    </BaseBanner>

    <!-- Quarterly upgrade CTA. -->
    <BaseBanner
      v-else-if="hasOpenBoxCarts && !hasCustomizableSubscriptions && isQuarterlySubscriber"
      v-bind="bannerProps"
      :class="`${customizationIsLive ? 'bg-spring' : 'bg-summer'}`"
      data-cy="customization-open"
    >
      <template v-slot:header>
        <div class="pt-2 md:pt-0">
          <span v-if="isSubscriptionOffWaitlist">Waitlist reserve</span>
          <span v-else-if="customizationIsLive">{{ boxTitleToDisplay }}</span>
          <span v-else>Spring is here!</span>
        </div>
      </template>
      <span v-if="isSubscriptionOffWaitlist">Your box is ready!</span>
      <span v-else-if="customizationIsLive" class="-mx-2 xs:mx-0"
        >Upgrade <br v-if="screen.xl" />to Customize</span
      >
      <span v-else class="text-31px">Want to customize?</span>
      <template v-slot:description>
        <div v-if="customizationIsLive" class="max-w-85 mx-auto xl:max-w-none">
          Want to pick your products? Upgrade to an annual membership. You’ll also get access to
          Choice+ which means you can add extras from any category!
        </div>
        <span v-else>
          The Spring Box is here. Want to pick your products? Upgrade to an Annual Membership.
          <span class="font-semibold"> customization opens {{ customizationStartDate }}. </span>
        </span>
      </template>
      <template v-slot:cta>Upgrade now</template>
      <template v-if="screenIsNotBetweenLgAndXl" v-slot:image>
        <BaseImage
          :class="`w-full ${screen.md ? 'py-30px pr-30px' : ''}`"
          :src="quarterlyImage"
          responsive="md"
          :sizes="{ default: '100vw', md: '50vw', xl: '384px' }"
          background-transparent
          alt=""
        />
      </template>
    </BaseBanner>

    <!-- everyone else -->
    <!-- for purgecss: bg-spring bg-summer bg-fall bg-winter -->
    <BaseBanner
      v-else
      size="large"
      text-position="center"
      :class="currentSeasonColor"
      data-cy="customization-closed"
    >
      {{ boxTitleToDisplay }}
      <template v-slot:description>
        <div>
          {{ currentSeason }} is here! To view the shipping timeline for current seasonal boxes
          please locate your box in our support center found
          <BaseLinkStyled
            type="link"
            color="black"
            href="https://faq.alltrue.com/hc/en-us/sections/360007879533-Seasonal"
          >
            HERE </BaseLinkStyled
          >.
        </div>
        <div class="font-semibold mt-3">
          <BaseLinkStyled
            type="link"
            color="black"
            href="https://instagram.com/alltrue"
            target="_blank"
            font-size="base"
            icon-position="left"
            :icon-size="5"
          >
            Follow us on Instagram
            <template v-slot:icon>
              <IconInstagram />
            </template>
          </BaseLinkStyled>
        </div>
      </template>
    </BaseBanner>
  </HomeWidget>
</template>
