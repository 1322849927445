<script>
  import HomeWidgetGrid from '@/components/home/HomeWidgetGrid'
  import HomeWidget from '@/components/home/HomeWidget'
  import screen from '@/helpers/screen'

  export default {
    components: {
      HomeWidgetGrid,
      HomeWidget,
    },
    computed: {
      screen,
    },
  }
</script>

<template>
  <component :is="screen.lg ? 'BaseContainer' : 'div'" :max-width="screen.lg ? '2xl' : undefined">
    <div class="px-4 lg:px-0 pb-10 md:py-6">
      <HomeWidgetGrid>
        <HomeWidget>
          <div class="widget flex-col items-center pb-8 lg:pb-0">
            <div class="gradient w-full h-20" />
            <div class="gradient rounded-full absolute top-10 w-25 h-25 inset-x-0 mx-auto" />
            <div class="gradient standard-line mt-20 lg:mt-30" />
            <div class="gradient medium-line mt-3" />
          </div>
        </HomeWidget>
        <HomeWidget :col-span="2">
          <div
            class="widget flex-col-reverse lg:flex-row items-center lg:items-start justify-between px-7 py-8 lg:py-0"
          >
            <div class="mt-10 lg:mt-28 flex flex-col">
              <div class="gradient center-widget-line" />
              <div class="gradient center-widget-line mt-3" />
              <div class="gradient center-widget-line mt-3" />
            </div>
            <div class="gradient w-3/4 lg:w-1/2 h-50 self-center" />
          </div>
        </HomeWidget>
        <HomeWidget v-for="index in 2" :key="`side-${index}`" :row-span="1">
          <div
            class="widget flex-col items-center lg:items-start justify-center lg:pl-7 py-8 lg:py-0"
          >
            <div class="gradient standard-line" />
            <div class="gradient standard-line mt-3" />
            <div class="gradient short-line mt-7" />
          </div>
        </HomeWidget>
        <HomeWidget v-for="index in 2" :key="`center-${index}`" :col-start="index === 1 ? 2 : 3">
          <div class="widget flex-col items-center justify-center py-8 lg:py-0">
            <div class="gradient standard-line" />
            <div class="gradient medium-line mt-3" />
          </div>
        </HomeWidget>
      </HomeWidgetGrid>
    </div>
  </component>
</template>

<style lang="postcss" scoped>
  .widget {
    @apply bg-dawn h-full flex;
  }

  .short-line {
    @apply w-20 h-3;
  }

  .medium-line {
    @apply w-36 h-3;
  }

  .standard-line {
    @apply w-40 h-3;
  }

  .center-widget-line {
    @apply w-42 h-3;

    @screen xl {
      @apply w-52 h-3;
    }
  }

  .gradient {
    background: linear-gradient(to right, #e7decc 8%, #ddd5c4 38%, #e7decc 54%);
    background-size: 1000px 640px;
    animation-name: placeHolderShimmer;
    animation-duration: 1.8s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
  }

  @keyframes placeHolderShimmer {
    0% {
      background-position: -468px 0;
    }

    100% {
      background-position: 468px 0;
    }
  }
</style>
