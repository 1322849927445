<script>
  import { get, call } from 'vuex-pathify'
  import dayjs from 'dayjs'
  import HomeCustomizationWidget from '@/components/home/HomeCustomizationWidget'
  import HomeUpdateWidgets from '@/components/home/HomeUpdateWidgets'
  import HomeMemberAchievements from '@/components/home/HomeMemberAchievements'
  import HomeMemberProfile from '@/components/home/HomeMemberProfile'
  import HomeWidget from '@/components/home/HomeWidget'
  import HomeWidgetGrid from '@/components/home/HomeWidgetGrid'
  import HomeLoadingSkeleton from '@/components/home/HomeLoadingSkeleton'
  import ProductSlider from '@/components/marketplace/ProductSlider'
  import AccountAddOnContent from '@/components/account/AccountAddOnContent'
  import HomeModalContent from '@/components/home/HomeModalContent'
  import HolidayModal from '@/components/modals/HolidayModal'
  import MobileNavLinks from '@/components/app/MobileNavLinks'
  import HomeWidgetSingleEntryModal from '@/components/home/HomeWidgetSingleEntryModal'
  import ShippingModal from '@/components/marketplace/ShippingModal'
  import createGuardMixin from '@/helpers/createGuardMixin'
  import screen from '@/helpers/screen'

  const guardMixin = createGuardMixin(
    async ({ store, redirect }) => {
      if ((await store.dispatch('account/loggedInAsync')) === false) {
        return redirect({ name: 'AccountLogin' })
      }
    },
    ['loggedIn']
  )

  export default {
    components: {
      HomeCustomizationWidget,
      HomeUpdateWidgets,
      HomeMemberAchievements,
      HomeMemberProfile,
      HomeWidget,
      HomeWidgetGrid,
      ProductSlider,
      AccountAddOnContent,
      HomeModalContent,
      HolidayModal,
      HomeWidgetSingleEntryModal,
      MobileNavLinks,
      HomeLoadingSkeleton,
      ShippingModal,
    },
    mixins: [guardMixin],
    data() {
      return {
        magazinePreviewModalOpen: false,
        widgetModalContent: null,
        holidayModalVisible: false,
        trendingQuestionsModalVisible: false,
        shippingModalVisible: false,
        latestBoxHasShipped: false, // state not yet available
      }
    },
    computed: {
      ...get('prismic', ['homeBannerData']),
      boxOnlyImage() {
        return this.homeBannerData.box_only_image.url || ''
      },
      heroBanner() {
        return this.homeBannerData.body[0].primary || ''
      },
      preparingBox() {
        return this.homeBannerData.body[2].primary || ''
      },
      pullingBox() {
        return this.homeBannerData.body[3].primary || ''
      },
      issueBox() {
        return this.homeBannerData.body[4].primary || ''
      },
      meetUsHere() {
        return this.homeBannerData.body[5].primary || ''
      },
      reactivateNow() {
        return this.homeBannerData.body[6].primary || ''
      },
      ...get('config', [
        'marketplaceEnabled',
        'marketplaceEndDate',
        'screenIsNotBetweenLgAndXl',
        'currentSeason',
        'currentBox',
        'isBoxCoreBox',
        'boxesById',
      ]),
      ...get('marketplace', ['trendingProducts']),
      ...get('feature', ['flags', 'isRushShippingEnabled']),
      ...get('account', [
        'loggedIn',
        'activeSubsWithoutMagazine',
        'allActiveSubsHaveMagazine',
        'allActiveSubsAreCanceled',
        'profileLoaded',
        'isSubscriptionCanceledWaitlist',
        'shipments',
      ]),
      ...get('customize', [
        'hasAwaitingShipmentSubscriptions',
        'findBoxBySubscription',
        'awaitingShipmentSubscriptions',
      ]),
      ...get('account', { accountLoaded: 'loaded' }),
      ...get('customize', { customizeLoaded: 'loaded' }),
      ...get('config', { configLoaded: 'loaded' }),
      screen,
      isAchievementsEnabled() {
        return this.flags.achievements ?? false
      },
      formattedMarketplaceEndDateLong() {
        return dayjs(this.marketplaceEndDate).format('MMMM D')
      },
      isMagazineEnabled() {
        return this.flags['addon-magazine'] ?? false
      },
      isEssentialsShopEnabled() {
        return !this.marketplaceEnabled && (this.flags['essentials-shop-enabled'] ?? false)
      },
      isCustomizationEnabled() {
        return !(this.flags['kill-customization'] ?? false)
      },
      latestAwaitingShipmentBox() {
        const latestAwaitingSubscription = this.awaitingShipmentSubscriptions?.[0]
        let latestAwaitingShipment = null
        let latestAwaitingBox = null

        if (latestAwaitingSubscription) {
          latestAwaitingShipment = this.shipments.find(({ subscriptionId }) => {
            return subscriptionId === latestAwaitingSubscription.id
          })
        }

        if (latestAwaitingShipment) {
          latestAwaitingBox = this.boxesById[latestAwaitingShipment.boxId]
        }

        return latestAwaitingBox
      },
      isLatestAwaitingShipmentTheCurrentBox() {
        return this.latestAwaitingShipmentBox?.id === this.currentBox.id
      },
      latestAwaitingShipmentBoxToDisplay() {
        let title = 'Winter Box'
        let season = 'winter'

        if (this.latestAwaitingShipmentBox) {
          title = this.latestAwaitingShipmentBox.label ?? this.latestAwaitingShipmentBox.title
          season = this.latestAwaitingShipmentBox.season.toLowerCase()
        }

        return {
          title,
          season,
        }
      },
      awaitingShipmentImage() {
        // if latest awaiting subscription is a core box, return normal box image
        if (this.isBoxCoreBox(this.latestAwaitingShipmentBox)) {
          return `${this.boxOnlyImage}`
        }
        // otherwise return welcome box image
        return `${this.pullingBox.image.url}`
      },
      boxHasShippedImage() {
        return `/static/home/box-shipped-sp22-${this.screen.md ? 'desktop' : 'mobile'}.gif`
      },
      warehouseModalSlug() {
        return this.isBoxCoreBox(this.latestAwaitingShipmentBox)
          ? 'spring-boxes-choice-orders'
          : 'bundle-pop-up-shop-orders-winter-boxes'
      },
      displayMagazineOptIn() {
        return this.activeSubsWithoutMagazine.length > 0
      },
      displayMagazinePreview() {
        return this.allActiveSubsHaveMagazine
      },
      magazineImage() {
        return '/static/magazine/magazine-home-banner-spring-22.jpg'
      },
      magazineImageRatio() {
        return 1
      },
      slidersPerView() {
        if (this.screen.md) {
          return 3
        }
        return 2
      },
      widgetModalBgColor() {
        if (this.widgetModalContent?.type === 'trending') {
          return 'primary'
        }
        return 'dawn'
      },
      showHolidayMarketComingSoon() {
        return new Date() < new Date('2022/2/23')
      },
      loaded() {
        return this.configLoaded && this.accountLoaded && this.profileLoaded && this.customizeLoaded
      },
    },
    async created() {
      await this.loadHomeBannerData()
      if (this.marketplaceEnabled) {
        this.$store.dispatch('products/ensureFresh')
      }
      this.$store.dispatch('customize/ensureFresh')
      this.$store.dispatch('account/fetchProfile') // populates account.subscriptions
      this.$cookies.set('visitedDashboard', true, '30d', null, '.alltrue.com')
    },
    methods: {
      ...call('prismic', ['loadHomeBannerData']),
      htmlSerializer(type, element, text, children) {
        if (text) {
          text = text.replace(
            '{{ formattedMarketplaceEndDateLong }}',
            this.formattedMarketplaceEndDateLong
          )
          return text
        }
      },
      ...call('marketplaceCart', ['addToCart']),
      toggleMagazinePreviewModal() {
        this.magazinePreviewModalOpen = !this.magazinePreviewModalOpen
      },
      toggleWidgetModal(update) {
        this.widgetModalContent = this.widgetModalContent ? null : update
      },
      viewUpdate({ type, update }) {
        if (type === 'warehouse') {
          this.$cookies.set(`warehouse${update.id}`, true, '10y')
        }
        this.widgetModalContent = { type, ...update }
      },
      openNextModal() {
        this.holidayModalVisible = false
        this.trendingQuestionsModalVisible = true
      },
    },
  }
</script>

<template>
  <div class="overflow-x-hidden lg:overflow-x-visible">
    <template v-if="loaded">
      <MobileNavLinks class="mb-8" />
      <component :is="screen.lg ? 'BaseContainer' : 'div'" max-width="2xl">
        <div class="px-4 lg:px-0 pb-10 md:py-6">
          <HomeWidgetGrid>
            <!-- Profile + Explore Past Boxes -->
            <HomeWidget
              v-if="screen.lg"
              :col-start="1"
              :row-start="1"
              :row-span="3"
              class="order-first sticky fixed-top grid gap-4 m-0"
              style="grid-template-rows: repeat(2, 158px)"
            >
              <HomeWidget :row-span="2">
                <HomeMemberProfile class="h-full" />
              </HomeWidget>

              <HomeWidget v-if="isAchievementsEnabled" :col-start="1">
                <HomeMemberAchievements />
              </HomeWidget>
              <HomeWidget :row-start="3">
                <button
                  class="bg-primary text-white text-center py-4 w-full group"
                  @click="$navigate({ name: 'BoxArchive' })"
                >
                  <span class="font-medium text-sm">Explore Past Boxes</span>
                  <BaseIcon
                    :size="2"
                    class="inline-block align-middle ml-4 transform duration-400 group-hover:translate-x-2"
                  >
                    <IconChevronRightThick />
                  </BaseIcon>
                </button>
              </HomeWidget>
            </HomeWidget>

            <!-- Market Place Banner: Open -->
            <HomeWidget
              v-if="marketplaceEnabled"
              :col-span="2"
              :col-start="2"
              :row-span="2"
              size="full"
              data-cy="market-banner"
            >
              <BaseBanner
                size="large"
                :text-position="screen.xl ? 'left' : 'center'"
                flex-layout="row-reverse"
                text-classes="md:flex-1"
                text-color="dawn"
                cta-button-color="dawn"
                class="bg-primary text-center"
                :class="'bg-' + heroBanner.background_color.toLowerCase()"
              >
                <div class="text-4xl lg:text-3xl font-semibold -mx-2">
                  <PrismicRichText v-if="heroBanner" :field="heroBanner.header" />
                </div>
                <template v-slot:description>
                  <div class="max-w-85 mx-auto xl:m-w-none" style="line-height: 1.3">
                    <div class="text-center xl:-mx-4">
                      <PrismicRichText
                        v-if="heroBanner"
                        :field="heroBanner.description_copy"
                        :html-serializer="htmlSerializer"
                      />
                    </div>
                  </div>
                </template>
                <template v-if="screenIsNotBetweenLgAndXl" v-slot:image>
                  <div class="square-banner-image w-full md:w-1/2 lg:w-full">
                    <BaseImage
                      :src="heroBanner.image.url"
                      :alt="heroBanner.image.alt || 'Banner Image'"
                      responsive="md"
                      :sizes="{ default: '100vw', md: '50vw', lg: '512px' }"
                      background-transparent
                    />
                  </div>
                </template>
                <template v-slot:cta
                  ><BaseLink :to="{ name: 'Market' }">Shop the market</BaseLink></template
                >
              </BaseBanner>
            </HomeWidget>

            <!-- Market Place Banner: Coming Soon! -->
            <HomeWidget
              v-else-if="showHolidayMarketComingSoon"
              :col-span="2"
              :col-start="2"
              :row-span="2"
              size="full"
            >
              <BaseBanner
                size="large"
                :text-position="screen.xl ? 'left' : 'center'"
                flex-layout="row-reverse"
                text-classes="xl:pt-10 flex-1 xl:-mx-5"
                text-color="dawn"
                class="bg-primary text-center"
              >
                <div class="text-center font-semibold pt-4 md:pt-0">
                  Coming Soon: <br />
                  Add-On Market
                </div>
                <template v-slot:description>
                  <div class="xl:max-w-60 mx-auto text-center px-1 md:px-0 pb-4 md:pb-0">
                    What’s that we see… bargains beginning to bud? Shop the members-only Add-On
                    Market from March 4-14 for sustainable, ethical goods at up to 70% off!
                  </div>
                </template>
                <template v-if="screenIsNotBetweenLgAndXl" v-slot:image>
                  <img
                    src="/static/home/MarketComingSoon.gif"
                    alt=""
                    :class="`${screen.md ? 'h-full md:max-w-1/2 object-cover' : ''}`"
                  />
                </template>
              </BaseBanner>
            </HomeWidget>

            <!-- Products Carousel -->
            <HomeWidget
              v-if="marketplaceEnabled && trendingProducts.length"
              :col-start="2"
              :col-span="2"
              :row-span="2"
            >
              <div class="overflow-hidden bg-dawn pl-4 py-4 h-full">
                <!-- make sure products aren't sold out -->
                <ProductSlider
                  :products="trendingProducts"
                  :slides-per-view="slidersPerView"
                  truncate-product-title
                  class="home-slider"
                  @addToCart="addToCart"
                >
                  <template v-slot:heading>
                    <div class="flex justify-between items-end">
                      <div class="flex">
                        <span class="text-lg">Shop Trending Products</span>
                      </div>
                      <BaseLink class="font-normal text-base mr-4" :to="{ name: 'Market' }">
                        Shop All
                      </BaseLink>
                    </div>
                  </template>
                </ProductSlider>
              </div>
            </HomeWidget>

            <!-- Await shipment (preparing to ship) -->
            <HomeWidget
              v-if="hasAwaitingShipmentSubscriptions"
              :col-start="2"
              :col-span="2"
              :row-span="screen.md ? 2 : 4"
            >
              <BaseBanner
                v-if="isBoxCoreBox(latestAwaitingShipmentBox)"
                size="large"
                :text-position="screen.xl ? 'left' : 'center'"
                text-classes="md:flex-1"
                cta-button-color="black"
                :class="
                  'bg-' + preparingBox.background_color.toLowerCase() + ' ' + 'cursor-default'
                "
                data-cy="customization-open"
              >
                <template v-slot:header>
                  <div class="pt-26px md:pt-0 md:text-xl">
                    <PrismicRichText v-if="preparingBox" :field="preparingBox.sub_header" />
                  </div>
                </template>
                <div class="py-1px lg:py-0">
                  <PrismicRichText v-if="preparingBox" :field="preparingBox.header" />
                </div>
                <template v-slot:description>
                  <div class="pb-10px md:pb-0">
                    <span class="inline-block max-w-80 xl:max-w-62">
                      <PrismicRichText v-if="preparingBox" :field="preparingBox.description_copy" />
                    </span>
                    <BaseLink
                      class="cursor-pointer block xl:whitespace-no-wrap"
                      @click="shippingModalVisible = true"
                      ><span class="underline">View fulfillment and shipping timelines.</span>
                    </BaseLink>
                  </div>
                </template>
                <template v-if="screenIsNotBetweenLgAndXl" v-slot:image>
                  <div class="square-banner-image-padded w-full md:w-1/2 lg:w-full">
                    <BaseImage
                      :class="`${screen.md ? 'pr-8 py-8' : !screen.md ? 'mt-2 w-full' : ''}`"
                      :src="awaitingShipmentImage"
                      responsive="md"
                      :sizes="{ default: '100vw', md: '50vw', xl: '384px' }"
                      background-transparent
                      alt=""
                    />
                  </div>
                </template>
              </BaseBanner>
              <!-- Latest box from shipments if subscription expired -->
              <!-- Expected last box: Winter Welcome Box Is Prepped -->
              <BaseBanner
                v-else
                size="large"
                :text-position="screen.xl ? 'left' : 'center'"
                text-classes="md:flex-1"
                cta-button-color="black"
                :class="`bg-${latestAwaitingShipmentBoxToDisplay.season} cursor-default`"
                data-cy="customization-open"
              >
                <template v-slot:header>
                  <div class="pt-26px md:pt-0 md:text-xl">{{
                    latestAwaitingShipmentBoxToDisplay.title
                  }}</div>
                </template>
                <div class="py-1px lg:py-0">
                  <PrismicRichText v-if="pullingBox" :field="pullingBox.header" />
                </div>
                <template v-slot:description>
                  <div class="pb-10px md:pb-0">
                    <PrismicRichText v-if="pullingBox" :field="pullingBox.description_copy" />
                  </div>
                </template>
                <template v-if="screenIsNotBetweenLgAndXl" v-slot:image>
                  <div class="square-banner-image-padded w-full md:w-1/2 lg:w-full">
                    <BaseImage
                      :class="`${screen.md ? 'pr-8 py-8' : !screen.md ? 'mt-2 w-full' : ''}`"
                      :src="awaitingShipmentImage"
                      responsive="md"
                      :sizes="{ default: '100vw', md: '50vw', xl: '384px' }"
                      background-transparent
                      alt=""
                    />
                  </div>
                </template>
              </BaseBanner>
            </HomeWidget>

            <!-- Box shipped -->
            <HomeWidget
              v-if="latestBoxHasShipped"
              :col-start="2"
              :col-span="2"
              :row-span="screen.md ? 2 : 4"
            >
              <BaseBanner
                size="large"
                :text-position="screen.xl ? 'left' : 'center'"
                text-classes="md:flex-1"
                class="bg-spring cursor-default"
              >
                <template v-slot:header>
                  <div class="pt-26px md:pt-0 md:text-xl">Spring Box</div>
                </template>
                <div class="py-1px lg:py-0 -mx-2 md:mx-0 xl:max-w-60">Your Box Is on the Way!</div>
                <template v-slot:description>
                  <div class="pb-10px md:pb-0">
                    <span class="inline-block max-w-75 xl:max-w-62"
                      >It’s all happening. You’ll receive a shipping confirmation email soon.</span
                    >
                  </div>
                </template>
                <template v-if="screenIsNotBetweenLgAndXl" v-slot:image>
                  <div class="square-banner-image-padded w-full md:w-1/2 lg:w-full">
                    <BaseImage
                      :class="`${screen.md ? 'pr-8 py-8' : !screen.md ? 'mt-2 w-full' : ''}`"
                      :src="boxHasShippedImage"
                      responsive="md"
                      :sizes="{ default: '100vw', md: '50vw', xl: '384px' }"
                      background-transparent
                      alt=""
                    />
                  </div>
                </template>
              </BaseBanner>
            </HomeWidget>

            <!-- My Box Banner -->
            <HomeCustomizationWidget
              v-if="
                !isSubscriptionCanceledWaitlist &&
                !isLatestAwaitingShipmentTheCurrentBox &&
                isCustomizationEnabled
              "
              :col-start="2"
              :col-span="2"
              :row-span="screen.md ? 2 : 4"
            />

            <!-- All Good Things -->
            <HomeWidget
              v-if="isMagazineEnabled && (displayMagazineOptIn || displayMagazinePreview)"
              :col-span="2"
              :row-span="2"
              :col-start="2"
              :to="
                displayMagazineOptIn
                  ? { name: 'AccountProfileSubscriptions', params: { section: 'magazine' } }
                  : undefined
              "
              class="cursor-pointer"
              data-cy="magazine-banner"
              v-on="displayMagazinePreview ? { click: toggleMagazinePreviewModal } : {}"
            >
              <BaseBanner
                size="large"
                :text-position="screen.xl ? 'left' : 'center'"
                text-color="dawn"
                text-classes="flex-1 md:min-w-1/2"
                :content-width="screen.xl ? 'half' : 'full'"
                cta-button-color="dawn"
                :display="screen.md && !screen.lg ? 'grid' : 'flex'"
                :class="'bg-' + issueBox.background_color.toLowerCase()"
              >
                <template v-slot:header>
                  <div class="pt-2 md:pt-0"
                    ><PrismicRichText v-if="issueBox" :field="issueBox.sub_header"
                  /></div>
                </template>
                <PrismicRichText v-if="issueBox" :field="issueBox.header" />
                <template v-slot:description>
                  <PrismicRichText v-if="issueBox" :field="issueBox.description_copy" />
                </template>
                <template v-slot:cta>
                  {{ displayMagazineOptIn ? 'Upgrade now' : `Preview ${currentSeason} Issue` }}
                </template>
                <template v-if="screenIsNotBetweenLgAndXl" v-slot:image>
                  <BaseImage
                    :src="issueBox.image.url"
                    :alt="issueBox.image.alt || 'Banner Image'"
                    :aspect-ratio="magazineImageRatio"
                    responsive="md"
                    :sizes="{ default: '100vw', md: '50vw', lg: '512px' }"
                    :class="`w-full ${screen.xl ? 'h-full' : ''}`"
                  />
                </template>
              </BaseBanner>
            </HomeWidget>

            <!-- Reactivate Banner -->
            <HomeWidget
              v-if="profileLoaded && allActiveSubsAreCanceled"
              :col-span="2"
              :row-span="screen.md ? 2 : 3"
              :col-start="2"
              :to="{ name: 'AccountProfileSubscriptions', params: { section: 'reactivate' } }"
            >
              <BaseBanner
                size="large"
                :text-position="screen.xl ? 'left' : 'center'"
                text-color="dawn"
                :content-width="screen.xl ? 'half' : 'full'"
                cta-button-color="dawn"
                :display="screen.md && !screen.lg ? 'grid' : 'flex'"
                :class="'bg-' + reactivateNow.background_color.toLowerCase()"
              >
                <PrismicRichText v-if="reactivateNow" :field="reactivateNow.header" />
                <template v-slot:description>
                  <PrismicRichText v-if="reactivateNow" :field="reactivateNow.description_copy" />
                </template>
                <template v-slot:cta>Update Membership</template>
                <template v-if="screenIsNotBetweenLgAndXl" v-slot:image>
                  <BaseImage
                    :class="`w-full ${screen.md ? 'py-30px pr-30px' : ''}`"
                    :src="reactivateNow.image.url"
                    :alt="reactivateNow.image.alt || 'Banner Image'"
                    responsive="md"
                    :sizes="{ default: '100vw', md: '50vw', xl: '384px' }"
                    background-transparent
                  />
                </template>
              </BaseBanner>
            </HomeWidget>

            <!-- Essentials Shop -->
            <HomeWidget
              v-if="isEssentialsShopEnabled"
              :col-start="2"
              :col-span="2"
              :row-span="2"
              :to="{ name: 'EssentialsHome' }"
            >
              <BaseBanner
                size="large"
                :text-position="screen.xl ? 'left' : 'center'"
                :content-width="screen.xl ? 'half' : 'full'"
                class="bg-black"
                text-color="dawn"
                cta-button-color="dawn"
                :display="screen.md && !screen.lg ? 'grid' : 'flex'"
              >
                <template v-slot:header>Now open to Members</template>
                Essentials Shop
                <template v-slot:description>
                  All your everyday essentials, without the daunting ingredients lists or dubious
                  production practices. Browse our curated selection today.
                </template>
                <template v-slot:cta>Shop now</template>
                <template v-if="screenIsNotBetweenLgAndXl" v-slot:image>
                  <BaseImage
                    :src="'/static/home/EssentialsShopOpen.png'"
                    alt=""
                    responsive="md"
                    :sizes="{ default: '100vw', md: '50vw', lg: '512px' }"
                    :class="`${screen.lg ? 'pr-34px py-8' : !screen.md ? 'mt-2 w-full' : ''}`"
                  />
                </template>
              </BaseBanner>
            </HomeWidget>

            <!-- Meet Us Here Podcast -->
            <HomeWidget
              :col-span="2"
              :col-start="2"
              :row-span="screen.xl ? 4 : 2"
              :href="meetUsHere.button_link.url"
              target="_blank"
            >
              <BaseBanner
                size="large"
                :text-position="screen.xl ? 'left' : 'center'"
                content-width="full"
                :class="'bg-' + meetUsHere.background_color.toLowerCase()"
                cta-button-color="black"
                flex-layout="col-reverse"
              >
                <template v-slot:header
                  ><PrismicRichText v-if="issueBox" :field="meetUsHere.sub_header"
                /></template>
                <PrismicRichText v-if="issueBox" :field="meetUsHere.header" />
                <template v-slot:description>
                  <p class="-mx-1 md:mx-0">
                    <PrismicRichText v-if="issueBox" :field="meetUsHere.description_copy" />
                  </p>
                </template>
                <template v-slot:cta>Listen now</template>
                <template v-if="screenIsNotBetweenLgAndXl" v-slot:image>
                  <div class="mt-6 px-8 flex md:h-54 md:h-auto justify-center">
                    <BaseImage
                      :src="meetUsHere.image.url"
                      alt="Alltrue Podcast"
                      responsive="lg"
                      :sizes="{ default: '100vw', xl: '640px' }"
                      background-transparent
                      class="px-12 md:px-0 xl:pb-12"
                    />
                  </div>
                </template>
              </BaseBanner>
            </HomeWidget>

            <HomeUpdateWidgets
              :row-span="4"
              :col-start="screen.lg ? 4 : 1"
              :row-start="1"
              class="lg:order-none lg:sticky fixed-top lg:grid lg:gap-4"
              style="grid-template-rows: repeat(4, 158px)"
              @viewUpdate="viewUpdate($event)"
            />

            <HomeWidget :col-start="2" :col-span="2" :row-span="1" class="grid grid-cols-2 gap-4">
              <HomeWidget size="half" :row-span="1" :to="{ name: 'AccountProfileOrders' }">
                <BaseBanner size="small" class="bg-dawn">
                  My Orders
                  <template v-slot:cta>View</template>
                </BaseBanner>
              </HomeWidget>
              <HomeWidget size="half" :row-span="1" :to="{ name: 'AccountProfileSubscriptions' }">
                <BaseBanner size="small" class="bg-dawn">
                  My Memberships
                  <template v-slot:cta>View</template>
                </BaseBanner>
              </HomeWidget>
            </HomeWidget>
          </HomeWidgetGrid>
        </div>
      </component>
      <Portal to="modal">
        <BaseModal
          size="sm-md"
          height="md"
          bg-color="dawn"
          :open="magazinePreviewModalOpen"
          dismissible
          @dismiss="toggleMagazinePreviewModal"
        >
          <AccountAddOnContent type="magazinePreview" />
        </BaseModal>
      </Portal>
      <Portal to="modal">
        <BaseModal
          size="sm-md"
          height="md"
          :open="Boolean(widgetModalContent)"
          :bg-color="widgetModalBgColor"
          hide-nav-on-mobile
          dismissible
          sticky-icon
          @dismiss="toggleWidgetModal"
        >
          <HomeModalContent :update="widgetModalContent" :bg-color="widgetModalBgColor" />
        </BaseModal>
      </Portal>

      <ShippingModal
        :open="shippingModalVisible"
        type="choicePlus"
        @dismiss="shippingModalVisible = false"
      />

      <!-- Rush Shipping Modals -->
      <HolidayModal
        v-if="isRushShippingEnabled"
        :open="holidayModalVisible"
        dismissible
        @openNextModal="openNextModal"
        @dismissAll="holidayModalVisible = false"
      />
      <HomeWidgetSingleEntryModal
        v-if="isRushShippingEnabled"
        :open="trendingQuestionsModalVisible"
        :update="{ type: 'trending', slug: 'holiday-delivery' }"
        override-date="<span class='font-medium'>SHIPPING &amp; DELIVERY</span>"
        @dismiss="trendingQuestionsModalVisible = false"
      />
    </template>
    <HomeLoadingSkeleton v-else />
  </div>
</template>

<style lang="postcss" scoped>
  /* purgecss start ignore */

  .fixed-top {
    @screen lg {
      top: 92px;
    }
  }

  .home-slider {
    & >>> {
      & > .slider {
        & > .swiper-container {
          @apply overflow-visible -ml-1 -mr-1;

          @screen lg {
            @apply pr-8;
          }
        }

        & > .swiper-navigation {
          .swiper-button-next {
            @apply mr-3 h-10 w-10;

            @screen lg {
              @apply -mt-17;
            }
          }

          .swiper-button-prev {
            @apply -ml-2px h-10 w-10;
          }
        }

        .slide {
          @apply px-2;
        }
      }
    }
  }

  /* 100% square image locked ratio w/o cropping. based on grid height */
  .square-banner-image {
    @screen xl {
      max-width: 332px;
    }
  }

  .square-banner-image-padded {
    @screen xl {
      max-width: 300px;
    }
  }
</style>
