<script>
  import { get } from 'vuex-pathify'
  import screen from '@/helpers/screen'

  export default {
    computed: {
      ...get('config', ['marketplaceEnabled']),
      ...get('feature', ['flags']),
      screen,
      currentMarket() {
        if (this.marketplaceEnabled) {
          return {
            label: 'Add-On Market',
            to: { name: 'Market' },
          }
        }
        return {
          label: 'Essentials Shop',
          to: { name: 'EssentialsHome' },
        }
      },
      isCustomizationEnabled() {
        return !(this.flags['kill-customization'] ?? false)
      },
      mobileNavLinks() {
        const navItems = [
          {
            label: 'Account',
            to: { name: 'AccountProfileSettings' },
          },
          this.currentMarket,
          {
            label: 'Explore Past Boxes',
            to: { name: 'BoxArchive' },
          },
        ]

        if (this.isCustomizationEnabled) {
          navItems.splice(1, 0, {
            label: 'My Box',
            to: { name: 'CustomizeStart' },
          })
        }

        return navItems
      },
    },
  }
</script>

<template>
  <div
    v-if="!screen.md"
    class="text-center bg-dawn overflow-x-auto overflow-y-hidden nav-scrollbar py-4"
    style="white-space: nowrap"
  >
    <BaseLink v-for="(link, linkIndex) in mobileNavLinks" :key="linkIndex" :to="link.to">
      <span v-if="link.label === 'Add-On Market'" class="market-live-mobile-nav"> Live </span>
      <span
        :class="`${$route.name === link.to.name ? 'font-semibold' : 'font-medium'} text-sm ${
          link.label === 'Add-On Market' ? 'pr-4' : 'px-4'
        } ${linkIndex < mobileNavLinks.length - 1 ? 'border-r border-black' : ''}`"
      >
        {{ link.label }}
      </span>
    </BaseLink>
  </div>
</template>

<style lang="postcss">
  /* purgecss start ignore */
  .market-live-mobile-nav {
    @apply -mt-2 ml-4 mr-2 pl-6px pr-1 py-1 bg-primary text-dawn-lt4 font-medium text-2xs leading-none uppercase tracking-wide;
  }

  .nav-scrollbar {
    &::-webkit-scrollbar {
      @apply hidden;
    }
  }
</style>
