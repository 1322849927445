<script>
  import linkMixin from '@/mixins/linkMixin'

  /**
   * A widget with varying grid spans for the home grid view. Can be a link or div.
   */
  export default {
    mixins: [linkMixin],
    props: {
      /** The number of columns this cell should span. */
      colSpan: {
        type: Number,
        default: 1,
        validator: (value) => [1, 2, 3, 4].includes(value),
      },
      /** The number of rows this cell should span. */
      rowSpan: {
        type: Number,
        default: 2,
        validator: (value) => [1, 2, 3, 4].includes(value),
      },
      /** The grid-column-start property for this cell. */
      colStart: {
        type: Number,
        default: undefined,
        validator: (value) => [1, 2, 3, 4].includes(value),
      },
      /** The grid-row-start property for this cell. */
      rowStart: {
        type: Number,
        default: undefined,
        validator: (value) => [1, 2, 3, 4, 5].includes(value),
      },
      size: {
        type: String,
        default: 'full',
        validator: (value) => ['half', 'full'].includes(value),
      },
    },
    computed: {
      classNames() {
        const names = [
          this.size === 'half' ? 'col-span-1' : 'col-span-2',
          this.colStart ? `md:col-start-${this.colStart}` : '',
          this.rowStart ? `md:row-start-${this.rowStart}` : '',
          `md:col-span-${this.colSpan}`,
          `row-span-${this.rowSpan}`,
        ]
        return names.join(' ')
      },
    },
  }
</script>

<template>
  <!-- for purgecss: md:col-span-1 md:col-span-2 md:col-span-3 md:col-span-4 row-span-1 row-span-2 row-span-3 row-span-4 md:col-start-2 md:col-start-3 md:col-start-4 md:row-start-1 md:row-start-2 md:row-start-3 md:row-start-4 md:row-start-5 -->
  <component
    :is="hasLink ? 'BaseLink' : 'div'"
    :class="classNames"
    v-bind="linkProps"
    v-on="$listeners"
  >
    <!-- @slot The content to go inside the widget. -->
    <slot />
  </component>
</template>
