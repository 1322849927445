<script>

  import { get } from 'vuex-pathify'
  import simplebar from 'simplebar-vue'
  import dayjs from 'dayjs'
  import HomeWidget from '@/components/home/HomeWidget'
  import screen from '@/helpers/screen'
  import bellLottie from '@/lotties/bell.json'
  import HelpMessaging from '../app/HelpMessaging.vue'

  export default {
    components: {
      HomeWidget,
      simplebar,
      HelpMessaging,
    },
    data() {
      return {
        bellLottie,
        bellLottieRef: null,
        warehouseViewMoreClicked: false,
        trendingViewMoreClicked: false,
      }
    },
    computed: {
      ...get('config', ['warehouseUpdates', 'trendingQuestions']),
      screen,
    },
    methods: {
      timeElapsed(timestamp) {
        return dayjs(timestamp).format('MMM DD')
      },
      updatesSliced(type) {
        const viewMore =
          type === 'warehouseUpdates' ? this.warehouseViewMoreClicked : this.trendingViewMoreClicked
        if (viewMore || this.screen.lg) {
          return this[type].filter(({ slug }) => !slug.includes('boxes-choice-orders'))
        }
        return this[type].slice(0, 3)
      },
      warehouseUpdateHasBeenViewed(warehouseId) {
        return this.$cookies.isKey(`warehouse${warehouseId}`)
      },
      countUnreadWarehouseUpdates() {
        return this.warehouseUpdates.reduce((total, update) => {
          if (!this.warehouseUpdateHasBeenViewed(update.id)) {
            total += 1
          }
          return total
        }, 0)
      },
      viewUpdate(type, update) {
        this.$emit('viewUpdate', { type, update })
        this.countUnreadWarehouseUpdates()
      },
    },
  }
</script>

<template>
  <HomeWidget v-bind="$attrs">
    <HomeWidget :row-start="1" :row-span="2" class="widget-drop-shadow bg-dawn mb-4 lg:mb-0">
      <component
        :is="screen.lg ? 'simplebar' : 'div'"
        data-simplebar-auto-hide="false"
        class="lg:h-83"
      >
        <div class="sticky top-0 z-20 bg-dawn">
          <h2 :class="`py-9px ${countUnreadWarehouseUpdates() > 0 ? 'pl-1 lg:pl-2' : 'pl-4'}`">
            <BaseIcon
              :size="countUnreadWarehouseUpdates() > 0 ? 9 : 5"
              :class="`inline-block align-middle ${
                countUnreadWarehouseUpdates() > 0 ? 'mr-1' : 'mr-2'
              }`"
            >
              <BaseLottie
                v-if="countUnreadWarehouseUpdates() > 0"
                v-ref="bellLottieRef"
                :src="bellLottie"
                autoplay
                :aspect-ratio="1"
              />
              <IconBell v-else />
              <div
                v-if="countUnreadWarehouseUpdates() > 0"
                class="absolute top-0 right-0 mt-1 flex justify-center items-center w-4 h-4 rounded-full bg-summer text-dawn-lt4 text-3xs"
              >
                <span class="leading-none">{{ countUnreadWarehouseUpdates() }}</span>
              </div>
            </BaseIcon>
            <span>Live Warehouse Updates</span>
          </h2>
          <hr class="border-gray-active" :style="{ borderTopWidth: $pxToRem(0.5) }" />
        </div>

        <ul class="lg:mb-9">
          <li
            v-for="(update, index) in updatesSliced('warehouseUpdates')"
            :key="index"
            tabindex="0"
            :class="`${
              warehouseUpdateHasBeenViewed(update.id) ? '' : 'bg-dawn-lt3'
            } cursor-pointer group`"
            @click="viewUpdate('warehouse', update)"
            @keydown.enter="viewUpdate('warehouse', update)"
          >
            <div
              v-if="!warehouseUpdateHasBeenViewed(update.id)"
              class="absolute top-0 left-0 mt-5 ml-5 lg:ml-4 bg-primary rounded-full w-10px h-10px"
            />
            <div
              :class="`ml-12 lg:ml-10 mr-4 text-xs ${
                warehouseUpdateHasBeenViewed(update.id) ? 'py-2' : 'py-4'
              } `"
            >
              <span
                :class="`group-hover:underline ${
                  warehouseUpdateHasBeenViewed(update.id) ? 'text-2xs' : 'font-medium'
                }`"
                >{{ update.headline }}</span
              >
              <div class="flex mt-2 text-2xs">
                <span>{{ timeElapsed(new Date(update.timestamp)) }}</span>
                <span class="mx-2">&#8729;</span>
                <div>
                  <span>
                    View
                    <BaseIcon
                      :size="2"
                      class="inline-block align-middle text-primary -mt-1px ml-2px"
                    >
                      <IconChevronRightThick />
                    </BaseIcon>
                  </span>
                </div>
              </div>
            </div>
            <hr class="border-gray-active" :style="{ borderTopWidth: $pxToRem(0.5) }" />
          </li>
        </ul>
        <div
          v-if="!screen.lg && warehouseUpdates.length > 3"
          class="pt-4 pb-5 flex items-center justify-center bg-dawn"
        >
          <BaseLinkStyled
            v-if="!warehouseViewMoreClicked"
            color="black"
            @click="warehouseViewMoreClicked = true"
          >
            View more
          </BaseLinkStyled>
          <BaseLinkStyled v-else color="black" @click="warehouseViewMoreClicked = false">
            View less
          </BaseLinkStyled>
        </div>
        <div v-if="screen.lg" class="sticky bottom-0 pointer-events-none">
          <div
            class="absolute bottom-0 h-12 w-full"
            style="background: linear-gradient(180deg, rgba(244, 235, 214, 0) 0%, #f4ebd6 80.5%)"
          />
        </div>
      </component>
    </HomeWidget>
    <HomeWidget class="widget-drop-shadow bg-primary text-dawn">
      <component
        :is="screen.lg ? 'simplebar' : 'div'"
        data-simplebar-auto-hide="false"
        class="lg:h-83 trending-scrollbar"
      >
        <div class="sticky top-0 z-20 bg-primary">
          <h2 class="py-4 pl-3 lg:pl-4">
            <BaseIcon :size="6" class="inline-block align-middle -mt-1 mr-2">
              <IconTrending />
            </BaseIcon>
            <span>Trending Member Questions</span>
          </h2>
          <hr class="border-dawn" :style="{ borderTopWidth: $pxToRem(0.5) }" />
        </div>

        <ul class="lg:mb-5">
          <li
            v-for="(update, index) in updatesSliced('trendingQuestions')"
            :key="index"
            class="cursor-pointer group"
            tabindex="0"
            @click="viewUpdate('trending', update)"
            @keydown.enter="viewUpdate('treanding', update)"
          >
            <div class="ml-12 lg:ml-10 mr-4 pt-4 pb-3">
              <div class="text-xs font-medium">
                <span class="group-hover:underline">{{ update.headline }}</span>
              </div>
              <div class="flex mt-2 text-2xs">
                <div>
                  <span>
                    View
                    <BaseIcon :size="2" class="inline-block align-middle text-dawn -mt-1px ml-2px">
                      <IconChevronRightThick />
                    </BaseIcon>
                  </span>
                </div>
              </div>
            </div>
            <hr
              v-if="index < updatesSliced('trendingQuestions').length - 1"
              class="border-dawn"
              :style="{ borderTopWidth: $pxToRem(0.5) }"
            />
          </li>
        </ul>
        <div
          class="px-1 py-4 bg-primary-dark text-dawn text-center text-xs z-20 lg:sticky bottom-0"
        >
          <HelpMessaging />
        </div>
        <div
          v-if="!screen.lg && trendingQuestions.length > 3"
          class="pt-4 pb-5 flex items-center justify-center"
        >
          <BaseLinkStyled
            v-if="!trendingViewMoreClicked"
            color="dawn"
            @click="trendingViewMoreClicked = true"
          >
            View more
          </BaseLinkStyled>
          <BaseLinkStyled v-else color="dawn" @click="trendingViewMoreClicked = false">
            View less
          </BaseLinkStyled>
        </div>
      </component>
    </HomeWidget>
  </HomeWidget>
</template>

<style lang="postcss">
  /* purgecss start ignore */
  @import 'simplebar/dist/simplebar.min.css';

  .simplebar-track.simplebar-vertical {
    @apply w-14px mt-15 mb-4;
  }

  .simplebar-scrollbar::before {
    @apply bg-dawn-dk2 mr-1;
  }

  .trending-scrollbar {
    .simplebar-track.simplebar-vertical {
      @apply mb-19;
    }

    .simplebar-scrollbar::before {
      @apply bg-gray-opacity68;
    }
  }

  .simplebar-scrollbar.simplebar-visible::before {
    @apply opacity-100;
  }

  .simplebar-content-wrapper {
    @screen lg {
      overscroll-behavior: contain;
    }
  }

  .fixed-top {
    @screen lg {
      top: 92px;
    }
  }

  .widget-drop-shadow {
    @screen lg {
      filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.25));
    }
  }

  .need-help-links a {
    text-decoration: underline;
  }
</style>
